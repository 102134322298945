import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';

type CarouselContext = {
  currentIndex: number;
  handleNext: () => void;
  handlePrev: () => void;
  setCurrentIndex: (index: number) => void;
};

const carouselContext = createContext<CarouselContext | null>(null);

type CarouselProviderProps = {
  children: ReactNode;
};

export const CarouselProvider = ({ children }: CarouselProviderProps) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const COUNT = React.Children.count(children);

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex + 1 === COUNT ? 0 : prevIndex + 1
    );
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex - 1 < 0 ? COUNT - 1 : prevIndex - 1
    );
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setCurrentIndex(currentIndex <= COUNT ? currentIndex + 1 : 0);
    }, 3000);

    return () => clearTimeout(timer);
  }, [currentIndex, COUNT]);

  const value = { currentIndex, setCurrentIndex, handleNext, handlePrev };

  return (
    <carouselContext.Provider value={value}>
      {children}
    </carouselContext.Provider>
  );
};

export const useCarousel = () => {
  const context = useContext(carouselContext);

  if (!context)
    throw new Error('useCarousel must be used within a CarouselProvider');

  return context;
};
