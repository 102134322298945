import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Root from './layouts/Root';
import Home from './pages/Home';
import './assets/css/global.css';
import Phrase from './pages/Phrase';
import Start from './pages/Start';
import NotFound from './pages/NotFound';

const routes = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    children: [
      { index: true, element: <Home /> },
      { path: 'start', element: <Start /> },
      { path: 'phrase', element: <Phrase /> },
      { path: '*', element: <NotFound /> },
    ],
  },
]);

function App() {
  return (
    <div className='font-inter'>
      <RouterProvider router={routes} />
    </div>
  );
}

export default App;
