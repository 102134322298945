import { VariantProps, cva } from 'class-variance-authority';
import React, { ComponentPropsWithoutRef, ElementType } from 'react';
import cn from '../lib/cn';

const textVariants = cva('text-pretty', {
  variants: {
    size: {
      12: 'text-[1.2rem] leading-[1.4] -tracking-[0.040rem]',
      14: 'text-[1.4rem] leading-[1.4] -tracking-[0.040rem]',
      17: 'text-[1.6rem] md:text-[1.7rem] leading-[1.4] -tracking-[0.080rem]',
      19: 'text-[1.8rem] md:text-[1.9rem] leading-[1.3] -tracking-[0.075rem]',
      21: 'text-[1.9rem] md:text-[2.1rem] leading-[1.5] -tracking-[0.090rem]',
      24: 'text-[2.0rem] md:text-[2.4rem] leading-[1.4] -tracking-[0.080rem]',
      28: 'text-[2.4rem] md:text-[2.8rem] leading-[1.3] -tracking-[0.050rem]',
      32: 'text-[3.2rem] leading-[1.2] -tracking-[0.15rem]',
      40: 'text-[3.2rem] md:text-[4.0rem] leading-[1.2] -tracking-[0.2rem]',
      48: 'text-[4.0rem] md:text-[4.8rem] leading-[1.15] -tracking-[0.25rem]',
      56: 'text-[4.0rem] md:text-[4.8rem] lg:text-[5.6rem] leading-[1.0] -tracking-[0.20rem]',
      64: 'text-[4.8rem] md:text-[5.6rem] lg:text-[6.4rem] leading-[1.0] -tracking-[0.40rem]',
      80: 'text-[4.8rem] md:text-[5.6rem] lg:text-[8rem] leading-[1.0] -tracking-[0.50rem]',
    },

    align: {
      left: 'text-left',
      right: 'text-right',
      center: 'text-center',
    },

    color: {
      'low-contrast': 'text-[#d5d5d5]',
      white: 'text-white',
    },

    weight: {
      400: 'font-normal',
      500: 'font-medium',
      600: 'font-semibold',
      700: 'font-bold',
    },

    transform: {
      lowercase: 'lowercase',
      uppercase: 'uppercase',
      capitalize: 'capitalize',
    },
  },

  defaultVariants: {
    size: 17,
    weight: 400,
    color: 'white',
  },
});

type TextOwnProps<TElement extends ElementType> = {
  className?: string;
  as?: TElement;
};

type TextProps<TElement extends ElementType> = TextOwnProps<TElement> &
  Omit<ComponentPropsWithoutRef<TElement>, keyof TextOwnProps<TElement>> &
  VariantProps<typeof textVariants>;

const Text = <TElement extends ElementType>(props: TextProps<TElement>) => {
  const { as, className, size, align, weight, transform, color, ...rest } =
    props;

  const Component = as || 'p';

  return (
    <Component
      className={cn(
        textVariants({ size, align, weight, transform, color, className })
      )}
      {...rest}
    />
  );
};

export default Text;
