import { useState } from 'react';

const useSendMail = () => {
  const [query, setQuery] = useState<{
    message: string | null;
    status: 'pending' | 'error' | 'success' | 'loading';
  }>({
    message: null,
    status: 'pending',
  });

  type SendMail = {
    to: string;
    from: string;
    message: string;
  };

  const sendMail = async ({ to, from, message }: SendMail) => {
    const EMAIL = 'X4567b@gmail.com';

    let data = {
      service_id: 'service_k8zuarb',
      template_id: 'template_aurt28k',
      user_id: 'YcYqG6Ye2GHk81gDv',
      template_params: {
        to_name: to,
        from_name: from,
        message: message,
        email: EMAIL,
      },
    };

    setQuery({
      message: null,
      status: 'loading',
    });

    try {
      const response = await fetch(
        'https://api.emailjs.com/api/v1.0/email/send',
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(data),
        }
      );

      if (!response.ok) {
        return setQuery({
          message: 'Unable to send email',
          status: 'error',
        });
      }

      return setQuery({
        message: 'An internal error occurred. Try again later',
        status: 'success',
      });
    } catch (error) {
      setQuery({
        message: (error as Error).message,
        status: 'error',
      });
    }
  };

  return { query, sendMail };
};

export default useSendMail;
