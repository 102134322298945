import React, { Fragment } from 'react';
import Ticker from '../components/Ticker';
import Text from '../components/Text';
import Button from '../components/Button';
import fixes from '../data/fixes';
import ConnectToast from '../parts/ConnectToast';

const Home = () => {
  return (
    <Fragment>
      <Ticker />

      <section className='flex flex-col items-center gap-12 mx-8 mt-12 lg:flex-row lg:mt-56'>
        <div className='gap-y-10 w-full p-8 lg:h-[400px] lg:px-24 flex flex-col justify-center rounded-[2rem] bg-gradient-to-r from-[#1d1f35] to-[#1d1f35]/5'>
          <Text
            as={'h1'}
            size={56}
            weight={600}
            transform={'capitalize'}
          >
            elite
            <Text
              as={'span'}
              size={56}
              weight={500}
              className='text-[#ceacd8]'
            >
              Fix
            </Text>
            <br /> collaboration platform
          </Text>

          <Text size={19}>
            EliteFix provides industry-leading Web3 and BlockChain a safe
            protocol and process encrypted by a superb encryption server. Your
            information never leaves our server or accessible to third-parties
            with end-to-end encryption.
          </Text>

          <ConnectToast>
            <Button>Connect wallet</Button>
          </ConnectToast>
        </div>

        <div className='w-full'>
          <img
            src='/assets/images/hero.png'
            alt='blockchain illustration'
            className='w-full h-[38rem] object-contain lg:-translate-x-48'
          />
        </div>
      </section>

      {/* selection section */}
      <section className='mx-auto xl:w-[1240px] mt-64 px-5 xl:px-0'>
        <div className='space-y-5'>
          <Text
            as={'h2'}
            size={40}
            weight={500}
            align={'center'}
            transform={'capitalize'}
          >
            Choose your fix
          </Text>
          <Text
            size={19}
            align={'center'}
          >
            Ensure your wallet is connected before making your selection
          </Text>
        </div>

        <div className='grid grid-cols-1 gap-12 mt-20 lg:grid-cols-3 xl:grid-cols-4'>
          {fixes.map((fix, index) => (
            <ConnectToast key={index}>
              <div className='h-40 from-[#1a1c33] bg-gradient-to-tr to-[#1a1c33] flex flex-col gap-y-5 items-center justify-center rounded-xl p-8 hover:from-blue-300 hover:to-blue-500 cursor-pointer'>
                <Text
                  as={'h4'}
                  size={21}
                  weight={500}
                  align={'center'}
                  transform={'capitalize'}
                >
                  {fix.title}
                </Text>
                <Text
                  size={14}
                  align={'center'}
                  color={'low-contrast'}
                  className='first-letter:capitalize'
                >
                  {fix.content}
                </Text>
              </div>
            </ConnectToast>
          ))}
        </div>
      </section>

      {/* footer section */}
      <footer className='mt-40 mx-auto pb-16 xl:w-[1240px] px-5 xl:px-0'>
        <div className='h-[1px] rounded-md bg-white overflow-clip'>
          <div className='w-full h-full bg-gradient-to-r from-red-700 to-blue-900 via-indigo-400'></div>
        </div>

        <div className='flex justify-between mt-8'>
          <Text>&copy; 2024. EliteFix</Text>

          <ul className='flex gap-x-8'>
            <Text
              as={'li'}
              transform={'capitalize'}
            >
              privacy policy
            </Text>
            <Text
              as={'li'}
              transform={'capitalize'}
            >
              terms & conditions
            </Text>
          </ul>
        </div>
      </footer>
    </Fragment>
  );
};

export default Home;
