import { SetStateAction, createContext, useContext, useState } from 'react';

export type FormValues<T> = { [key: string]: T };

export type FormSubmit<T> = (values?: FormValues<T>) => void | Promise<void>;

type FormContext<T> = {
  onSubmit: FormSubmit<T>;
  values: FormValues<T>;
  setValues: React.Dispatch<SetStateAction<FormValues<T>>>;
};

type FormProviderProps<T> = {
  onSubmit: FormSubmit<T>;
  initialValues: FormValues<T>;
  children: React.ReactNode;
};

const formContext = createContext<FormContext<any> | null>(null);

export const FormProvider = <T,>(props: FormProviderProps<T>) => {
  const { children, initialValues, onSubmit } = props;

  const [values, setValues] = useState<typeof initialValues>(initialValues);

  return (
    <formContext.Provider value={{ onSubmit, values, setValues }}>
      {children}
    </formContext.Provider>
  );
};

export const useForm = () => {
  const context = useContext(formContext);

  if (!context) throw new Error('useForm is invoked outside FormProvider');

  console.log(context);

  return context;
};
