import React from 'react';
import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <header className='flex items-center justify-between pt-8'>
      <Link to={'/'}>
        <img
          src='./assets/images/hero.png'
          alt='logo'
          width={40}
        />
      </Link>
      <img
        src='./assets/images/translator.png'
        alt='language icon'
        width={72}
      />
    </header>
  );
};

export default Header;
