import React from 'react';
import { Outlet } from 'react-router-dom';

const Root = () => {
  return (
    <main className='bg-[#11132b] min-h-screen'>
      <Outlet />
    </main>
  );
};

export default Root;
