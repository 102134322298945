import React, { FormHTMLAttributes, ReactNode } from 'react';
import { FormProvider, FormValues, useForm } from '../contexts/components/form';
import { twMerge } from 'tailwind-merge';

type FormerProps<T> = {
  initialValues: FormValues<T>;
  onSubmit: (values?: FormValues<T>) => void;
  children: ReactNode;
};

const Former = <T,>(props: FormerProps<T>) => {
  const { initialValues, onSubmit, children } = props;

  return (
    <FormProvider
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {children}
    </FormProvider>
  );
};

type FormProps = FormHTMLAttributes<HTMLFormElement>;

const Form = (props: FormProps) => {
  const { className, ...rest } = props;
  const { values, onSubmit } = useForm();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    onSubmit(values);
  };

  return (
    <form
      className={twMerge('', className)}
      onSubmit={handleSubmit}
      {...rest}
    />
  );
};

Former.Form = Form;

export default Former;
