import { VariantProps, cva } from 'class-variance-authority';
import { ButtonHTMLAttributes } from 'react';
import cn from '../lib/cn';

const buttonVariants = cva(
  'rounded-full w-fit px-10 text-[1.4rem] font-medium',
  {
    variants: {
      variant: {
        solid:
          'bg-[#6649a9] text-white hover:bg-[#6649a9]/30 transition-all duration-300 ease-in-out',
        ghost: 'text-[#6649a9]',
      },

      size: {
        xs: 'py-2',
        sm: 'py-3',
        md: 'py-4',
        lg: 'py-5',
        xl: 'py-6',
      },
    },
    defaultVariants: {
      size: 'md',
      variant: 'solid',
    },
  }
);

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> &
  VariantProps<typeof buttonVariants>;

const Button = (props: ButtonProps) => {
  const { className, size, variant, ...rest } = props;

  return (
    <button
      className={cn(buttonVariants({ size, variant, className }))}
      {...rest}
    />
  );
};

export default Button;
