import React, { HTMLAttributes } from 'react';
import Portal from '../lib/portal';
import { twMerge } from 'tailwind-merge';
import { ToastProvider, useToast } from '../contexts/components/toast';

type ToastProps = {
  children: React.ReactNode;
  openFor?: number;
};

const Toast = ({ children, openFor }: ToastProps) => {
  return <ToastProvider openFor={openFor}>{children}</ToastProvider>;
};

type TriggerProps = HTMLAttributes<HTMLDivElement>;

const Trigger = (props: TriggerProps) => {
  const { setIsOpen } = useToast();

  return (
    <div
      onClick={() => setIsOpen(true)}
      {...props}
    />
  );
};

type DismissProps = HTMLAttributes<HTMLDivElement>;

const Dismiss = (props: DismissProps) => {
  const { setIsOpen } = useToast();

  return (
    <div
      onClick={() => setIsOpen(false)}
      {...props}
    />
  );
};

type ContentProps = HTMLAttributes<HTMLDivElement>;

const Content = (props: ContentProps) => {
  const { className, ...rest } = props;
  const { isOpen } = useToast();

  if (!isOpen) {
    return null;
  }

  return Portal({
    children: (
      <div
        className={twMerge(
          'font-inter fixed max-h-screen overflow-hidden',
          className
        )}
        {...rest}
      />
    ),
    container: document.getElementById('portal'),
  });
};

Toast.Trigger = Trigger;
Toast.Dismiss = Dismiss;
Toast.Content = Content;

export default Toast;
