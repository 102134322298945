import React from 'react';
import Text from '../components/Text';
import Button from '../components/Button';
import { Link } from 'react-router-dom';
import Header from '../parts/Header';

const options = [
  {
    title: 'Bring in a wallet',
    content: 'Get started with the EliteFix  within minutes.',
    action: 'add wallet',
  },
  {
    title: 'Restore with Phrase',
    content: 'Restore your existing wallet in two easy steps.',
    action: 'import existing wallet',
  },
];

const Start = () => {
  return (
    <div className='xl:w-[1180px] px-5 xl:px-0 mx-auto'>
      <Header />
      <div className='flex flex-col items-center justify-center pb-8 mt-12 h-fu ll lg:mt-80 gap-x-40 gap-y-20 lg:flex-row'>
        {options.map((option, index) => (
          <div
            key={index}
            className='flex flex-col items-center text-center'
          >
            <img
              src='/assets/images/wallet.svg'
              alt='wallet illustration'
            />
            <Text
              as={'h2'}
              size={24}
              className='mt-10'
            >
              {option.title}
            </Text>
            <Text className='mt-2'>{option.content}</Text>
            <Link to={'/phrase'}>
              <Button className='mt-10 capitalize'>{option.action}</Button>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Start;
