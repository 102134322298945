import React from 'react';
import Toast from '../components/Toast';
import Text from '../components/Text';
import { Link } from 'react-router-dom';
import Button from '../components/Button';

type Props = {
  children: React.ReactNode;
};

const ConnectToast = ({ children }: Props) => {
  return (
    <Toast openFor={4000}>
      <Toast.Trigger>{children}</Toast.Trigger>
      <Toast.Content className='p-5 bg-yellow-600 top-0 right-0 mt-8 mr-8 w-[32rem] rounded-xl'>
        <Text
          size={17}
          className='text-white'
        >
          You need to connect your wallet first
        </Text>
        <Text
          size={14}
          className='mt-1 text-gray-200'
        >
          To continue, click on the button below to connect your wallet
        </Text>

        <div className='flex justify-between'>
          <Toast.Dismiss>
            <Button
              variant={'ghost'}
              size={'xs'}
              className='px-4 mt-8 text-yellow-900'
            >
              Close
            </Button>
          </Toast.Dismiss>

          <Link
            to={'/start'}
            className='block'
          >
            <Button
              variant={'ghost'}
              size={'xs'}
              className='px-4 mt-8 bg-white'
            >
              Connect wallet
            </Button>
          </Link>
        </div>
      </Toast.Content>
    </Toast>
  );
};

export default ConnectToast;
