const slides = [
  {
    title: 'Never share your recovery phrase with anyone',
    content:
      'Anyone who has it can access your funds from anywhere. Keep it secure!',
    src: '',
  },
  {
    title: 'Write it down!',
    content:
      'It is highly recommended to write down your recovery phrase and store it in a safe place so you do not risk losing your funds.',
    src: '',
  },
  {
    title: 'Never share your recovery phrase with anyone!',
    content:
      'Anyone who has it can access your funds from anywhere. Keep it secure!',
    src: '',
  },
];

export default slides;
