import React from 'react';
import { createPortal } from 'react-dom';

type Props = {
  children: React.ReactNode;
  container: Element | null;
};

const Portal = ({ children, container }: Props) => {
  if (!container) {
    throw new Error('Could not find document within the DOM.');
  }

  return createPortal(children, container);
};

export default Portal;
