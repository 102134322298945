import React, { TextareaHTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';
import { useForm } from '../contexts/components/form';

type TextareaProps = TextareaHTMLAttributes<HTMLTextAreaElement>;

const Textarea = (props: TextareaProps) => {
  const { className, name, ...rest } = props;
  const { values, setValues } = useForm();

  if (!name) throw new Error('Name must be provided');

  const handleInputChange = (value: string) => {
    setValues({ ...values, [name]: value });
  };

  return (
    <textarea
      value={values[name]}
      rows={8}
      className={twMerge(
        'bg-transparent outline-none w-full ring-1 rounded focus-within:ring-gray-200 ring-gray-600 transition-all duration-300 ease-in-out text-white text-[1.4rem] p-3',
        className
      )}
      onChange={(e) => handleInputChange(e.target.value)}
      {...rest}
    />
  );
};

export default Textarea;
