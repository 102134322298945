import React, { HTMLAttributes, PropsWithChildren, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';
import { CarouselProvider, useCarousel } from '../contexts/components/Carousel';

type CarouselProps = Omit<HTMLAttributes<HTMLDivElement>, 'children'> & {
  children: ReactNode[];
};

const Carousel = (props: CarouselProps) => {
  const { children, className, ...rest } = props;

  return (
    <CarouselProvider>
      <div
        className={twMerge('overflow-hidden', className)}
        {...rest}
      >
        {React.Children.map(children, (child, index) => {
          return React.cloneElement(child as React.ReactElement<any>, {
            key: index,
            index: index,
            count: React.Children.count(children),
          });
        })}
      </div>
    </CarouselProvider>
  );
};

type ItemProps = HTMLAttributes<HTMLDivElement> & {
  index?: number;
};

const Item = (props: PropsWithChildren<ItemProps>) => {
  const { className, index, ...rest } = props;
  const { currentIndex } = useCarousel();

  if (currentIndex !== index) return null;

  return (
    <div
      className={twMerge('text-center', className)}
      {...rest}
    />
  );
};

Carousel.Item = Item;

type IndicatorProps = HTMLAttributes<HTMLDivElement> & {
  count?: number;
};

const Indicator = (props: PropsWithChildren<IndicatorProps>) => {
  const { className, count, ...rest } = props;

  const { currentIndex, setCurrentIndex } = useCarousel();

  const items = () => {
    const store = [];
    if (count) {
      for (let i = 0; i < count - 1; i++) {
        store.push(i);
      }
    }

    return store;
  };

  return (
    <div
      className={twMerge('flex gap-x-3 justify-center')}
      {...rest}
    >
      {items().map((item) => (
        <div
          key={item}
          className={twMerge(
            'size-4 bg-transparent border border-blue-400 rounded-full cursor-pointer',
            currentIndex === item && 'bg-blue-400',
            className
          )}
          onClick={() => setCurrentIndex(item)}
          {...rest}
        ></div>
      ))}
    </div>
  );
};

Carousel.Indicator = Indicator;

export default Carousel;
