import React, {
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';

type ToastContext = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<SetStateAction<boolean>>;
};

const toastContext = createContext<ToastContext | null>(null);

type ToastProviderProps = {
  children: React.ReactNode;
  openFor?: number;
};

export const ToastProvider = (props: ToastProviderProps) => {
  const { children, openFor } = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    if (openFor) {
      const timer = setTimeout(() => setIsOpen(false), openFor);
      return () => clearTimeout(timer);
    }
  });

  return (
    <toastContext.Provider value={{ isOpen, setIsOpen }}>
      {children}
    </toastContext.Provider>
  );
};

export const useToast = () => {
  const context = useContext(toastContext);

  if (!context) {
    throw new Error('Ensure useToast is invoked within ToastProvider');
  }

  return context;
};
