import React from 'react';
import Text from '../components/Text';
import Button from '../components/Button';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <div className='flex items-center justify-center h-screen'>
      <div className='text-center max-w-[400px] px-5 sm:px-0'>
        <Text size={28}>Page not found</Text>
        <Text className='mt-2'>
          We could not find such the page. Contact admin if you need help or go
          back to homepage.
        </Text>
        <Link
          to={'/'}
          className='block mt-10'
        >
          <Button>Go to Homepage</Button>
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
