const fixes = [
  {
    title: 'migration',
    content: 'click here for migrations',
  },
  {
    title: 'claim',
    content: 'click here to claim assets',
  },
  {
    title: 'swap',
    content: 'click here for assets swapping',
  },
  {
    title: 'slippage',
    content: 'click here for slippage related error',
  },
  {
    title: 'transaction',
    content: 'click here for transaction related issues',
  },
  {
    title: 'cross transfer',
    content: 'click here for cross bridge issues',
  },
  {
    title: 'stacking',
    content: 'click here for stacking related issues',
  },
  {
    title: 'exchange',
    content: 'click here for exchange related issues',
  },
  {
    title: 'connect to dapps',
    content: 'click here for error while connecting to dapps',
  },
  {
    title: 'login',
    content: 'click here for wallet login issues',
  },
  {
    title: 'whitelist',
    content: 'click here for whitelist related issues',
  },
  {
    title: 'NFT rarity and traits',
    content: 'click here for NFT related issues',
  },
  {
    title: 'buy coins/tokens',
    content: 'to trade your account must be marked as a trusted payment source',
  },
  {
    title: 'missing/irregular balance',
    content: 'click here to recover lost/missing funds',
  },
  {
    title: 'wallet glitch/error',
    content: 'click here if you have problem with your trading wallet',
  },
  {
    title: 'transaction delay',
    content: 'click here for any issues related to transaction delay',
  },
  {
    title: 'claim airdrop',
    content: 'click here for airdrop related issues',
  },
  {
    title: 'NFTs',
    content: 'click here for NFTs minting/transfer related issues',
  },
  {
    title: 'locked account',
    content: 'click here for issues related to account lock',
  },
];

export default fixes;
